import React, { useEffect, useState } from 'react'
import { AppRouter } from './AppRouter'
import { ErrorContainer } from './components/ErrorContainer'
import { GlobalStyle } from '@/styles/global'
import { ApiErrorMessage } from './components/ApiErrorMessage'
import { setToken, setUser } from '@/store/modules/auth/actions'
import { useAppDispatch } from '@/hooks'
import { getSessionContext } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { ConfirmationServiceProvider } from './components/ConfirmationService'
import { AppContainer } from './styles'
import { AppProps } from './types'

export const App = ({ token }: AppProps) => {
	const dispatch = useAppDispatch()
	const [isSessionContextLoaded, setIsSessionContextLoaded] = useState(false)
	const request = useApiRequest()

	useEffect(() => {
		const updateToken = async () => {
			if (token) {
				dispatch(setToken(token))

				if (!isSessionContextLoaded) {
					await request(getSessionContext()).then((value) => {
						value.data?.user && dispatch(setUser(value.data.user))
						setIsSessionContextLoaded(true)
					})
				}
			}
		}

		updateToken()
	}, [dispatch, isSessionContextLoaded, request, token])

	return (
		<AppContainer>
			<GlobalStyle />
			<ErrorContainer>
				<ConfirmationServiceProvider>
					<ApiErrorMessage />
					{isSessionContextLoaded && <AppRouter />}
				</ConfirmationServiceProvider>
			</ErrorContainer>
		</AppContainer>
	)
}
