import { ValueWrapper } from '@/components'

interface SecretInputProps {
	isDisabled: boolean
	value: string
}

export const SecretInput = ({ value, isDisabled }: SecretInputProps) => {
	return (
		<ValueWrapper title={value} disabled={isDisabled}>
			<input type="password" value={value ?? undefined} readOnly />
		</ValueWrapper>
	)
}
